* {
  font-family: 'El Messiri', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  font-family: 'El Messiri', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  min-height: 80vh;
  overflow-x: hidden;
}

body {
  color: white;
  background-color: #010F1E;
  overflow-x: hidden;
}

button {
  cursor: pointer;
}

.BlurCircle {
  position: absolute;
  width: 500px;
  height: 500px;
  z-index: -1;
  background: linear-gradient(to bottom right, #5D0F11, #04203E 80%);;
  filter: blur(40px);
}
.TopRight{
  border-radius: 0px 0px 0px 100%;
  top: 0px;
  right: 0px;
}
.TopLeft{
  border-radius: 0px 0px 100% 0px;
  width: 150px;
  height: 150px;
  top: 0px;
  left: 0px;
}

a{
  text-decoration: none;
}

.LoadingContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.AppBtn {
  width: 200px;
  height: 50px;
  margin: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  border-radius: 12px;
  font-weight: 600;
  color: white;
  border-style: solid;
  border-color: #3C1521;
  background: #3C1521;
  margin-top: 20px;
}

@media only screen and (max-width: 950px) {
	.AppBtn {
		width: 40.8vw;
		max-width: 200px;
		height: 10.2vw;
		max-height: 50px;
		font-size: 19px;
	}
}

@media only screen and (max-width: 450px) {
	.AppBtn {
		width: 40.8vw;
		max-width: 200px;
		height: 10.2vw;
		max-height: 50px;
		font-size: 4.5vw;
	}
}