.AuthSlogan{
	font-size: 18px;
	margin-top: 10px;
	margin-bottom: 5px;
}
.AuthTitle{
	font-size: 32px;
	font-weight: 500;
	margin-bottom: 30px;
}
.AuthContainer{
	width: 90%;
	max-width: 600px;
	height: 300px;
	border-radius: 20px;
	margin: 0px auto 40px auto;
	background: linear-gradient(to right, #5D0F11, #04203E 80%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.AuthContainer input{
	width: 70%;
	height: 40px;
	border-radius: 10px;
	padding: 1px 15px;
	font-size: 17px;
}
.AuthContainer button{
	width: 160px;
	height: 40px;
	border-radius: 10px;
	background: white;
	font-size: 18px;
	color: black;
	font-weight: 600;
	margin-top: 40px;
}
