body {
  scroll-behavior: smooth;
  margin: 0;
  font-family: 'El Messiri', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  overflow-x: hidden !important;
}

a{
  text-decoration: none;
}