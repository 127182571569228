.About{
	width: 100%;
	/* height: 400px; */
	margin: 20px auto 0px auto;
	padding: 50px 0px 40px 0px;
	text-align: justify;
}
.AboutContainer{
	margin: 0px auto;
	max-width: 860px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
.AboutContent{
	margin: 0 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: left;
	width: 90%;
	max-width: 440px;
	color: #a5abab;
	font-size: 17px;
	font-weight: 300;
}
.AboutContainer > img{
	width: 370px;
	height: 370px;
	border-radius: 12px;
}
.AboutTitle{
	font-size: 32px;
	font-weight: 500;
	margin-bottom: 20px;
	color: white;
}
.AboutIcons{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin-top: 35px;
}

.AboutIcons img{
	/* margin: 0 10px; */
	width: 40px;
}

.AboutImgContainer {
	width: 100%;
	max-width: 340px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.AboutImgContainer img {
	width: 90%;
	border-radius: 15px;
}


@media (max-width: 835px) {
	.About {
		margin: 0;
	}
	.AboutContainer {
		flex-direction: column;
		align-items: center;
		margin-top: -50px
	}
	.AboutContent {
		margin-bottom: 30px;
		max-width: unset;
	}
	.AboutImgContainer {
		max-width: unset;
	}
}